import { Box } from '@mui/material'
import React from 'react'
import { useNavbar } from '../contexts/NavbarContext'

const Carrier = () => {
  const { pageStyle } = useNavbar()
  return <Box style={{ ...pageStyle, marginBottom: '24px' }}>Carrier Page</Box>
}

export default Carrier
