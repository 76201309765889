import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { Box, Divider, Grid, Tab, Typography, useTheme } from '@mui/material'
import { FieldGroup, PhoenixBaseCard, PhoenixBaseTable } from 'componix'
import { MRT_ColumnDef } from 'material-react-table'
import { useTabs } from '../../hooks/useTabs'
import AddressDTOModel from '../../models/AddressDTOModel'
import {
  EmployerLocationModel,
  UnmatchedTransactionModel,
} from '../../models/WorkItemDetails/UnmatchedTransactionModel'
import { addressFormatter } from '../../utils/addressFormatter'
import { tableCellTitleCase } from '../../utils/table/tableCellTitleCase'

export const EmployersLocationsColumn: MRT_ColumnDef<EmployerLocationModel>[] = [
  {
    accessorKey: 'fein',
    header: 'FEIN',
  },
  {
    accessorKey: 'nameLinkCode',
    header: 'Name Link Code',
  },
  {
    accessorKey: 'employer',
    header: 'Employer Name',
    Cell: ({ cell }) => tableCellTitleCase(cell.getValue<string>()),
  },
]

interface TransactionDetailsProps {
  data: UnmatchedTransactionModel | undefined
  isLoading: boolean
}

const TransactionDetails = ({ data, isLoading }: TransactionDetailsProps) => {
  const theme = useTheme()
  const { tabIndex, handleTabChange } = useTabs()

  return (
    <Box p={0.5} flex={1}>
      <PhoenixBaseCard cardTitle="Transaction Details" variantType="Secondary" collapsible isLoading={isLoading}>
        <Box flex={1} p={2}>
          <Grid container>
            <Grid item xs={4}>
              <FieldGroup label={'Transaction Code'} value={data?.transactionDetails?.transactionCode} />
            </Grid>
            <Grid item xs={4}>
              <FieldGroup label={'Received Date'} value={data?.transactionDetails?.receivedDate} />
            </Grid>
            <Grid item xs={12}>
              <TabContext value={tabIndex}>
                <TabList onChange={handleTabChange}>
                  <Tab label={'Policy Information'} value={'0'}></Tab>
                  <Tab label={'Employers/Locations'} value={'1'}></Tab>
                </TabList>
                <Divider />
                <TabPanel value="0" sx={{ padding: 0 }}>
                  <Grid container spacing={2} pt={2}>
                    <Grid item xs={2.4}>
                      <FieldGroup label={'Carrier ID'} value={data?.transactionDetails?.policyInformation.carrierID} />
                    </Grid>
                    <Grid item xs={2.4}>
                      <FieldGroup
                        label={'Policy Number'}
                        value={data?.transactionDetails?.policyInformation.policyNumber}
                      />
                    </Grid>
                    <Grid item xs={2.4}>
                      <FieldGroup
                        label={'Effective Date'}
                        value={data?.transactionDetails?.policyInformation.effectiveDate}
                      />
                    </Grid>
                    <Grid item xs={2.4}>
                      <FieldGroup
                        label={'Expiration Date'}
                        value={data?.transactionDetails?.policyInformation.expirationDate}
                      />
                    </Grid>
                    <Grid item xs={2.4}>
                      <FieldGroup label={'Issue Date'} value={data?.transactionDetails?.policyInformation.issueDate} />
                    </Grid>
                    <Grid item xs={6}>
                      <FieldGroup
                        label={'Legal Nature'}
                        value={data?.transactionDetails?.policyInformation.legalNature}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FieldGroup
                        label={'Prior Policy Number'}
                        value={data?.transactionDetails?.policyInformation.priorNumber}
                      />
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value="1" sx={{ padding: 0 }}>
                  <PhoenixBaseTable
                    columns={EmployersLocationsColumn}
                    data={data?.transactionDetails?.employerLocations ?? []}
                    showTableWithoutData
                    overrides={{
                      enableExpanding: true,
                      enableExpandAll: true,
                      //eslint-disable-next-line @typescript-eslint/no-explicit-any
                      renderDetailPanel: ({ row }: any) => {
                        return row.original.locations.map((address: AddressDTOModel, index: number) => (
                          <div key={index}>
                            <Box key={index} p={2}>
                              <Typography variant={'overline'} lineHeight={1.5}>
                                Address
                              </Typography>
                              <Typography variant={'body1'}>{addressFormatter(address)}</Typography>
                            </Box>
                            <Divider />
                          </div>
                        ))
                      },
                      muiDetailPanelProps: () => ({
                        sx: {
                          backgroundColor: theme.palette.grey[100],
                          padding: 0,
                        },
                      }),
                    }}
                  />
                </TabPanel>
              </TabContext>
            </Grid>
          </Grid>
        </Box>
      </PhoenixBaseCard>
    </Box>
  )
}

export default TransactionDetails
